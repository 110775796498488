<template>
	<div class="list" v-if="modelValue">
		<div class="filter">
			<div class="search-box">
				<span class="search-icon icon-5"></span>
				<input @compositionstart="handleCompositionStart"
					   @compositionend="handleCompositionEnd" ref="input" class="input" type="text" v-model="filter.value" placeholder="搜索企业名称" @input="input"/>
				<span class="clear-icon icon-10" v-show="filter.value.length>0" @click="clear"></span>
			</div>
			<p class="btn" @click="syncAllDepartmentForCompany">
				<span class="icon icon-7"></span>
				<span class="btn-name">同步企业</span>
			</p>
		</div>
		<div class="list-box" @scroll="scroll">
			<div class="list-corp" v-for="item in list" @click="toUser(item)">
				<p class="corp-name">
					{{item.corp_name}}
				</p>
				<p class="col">
					<span class="col-one">管理员：{{item.admin}}</span>
					<span class="col-one">成员：{{item.user_total}}</span>
				</p>
			</div>
			<no-data v-show="list.length==0"></no-data>
		</div>
		<van-dialog v-model:show="loading" title="" :show-confirm-button="false">
			<div style="display: flex;justify-content: center;padding-top: .1rem;">
				<img style="width: 1rem;height: 1rem;" src="@/assets/img/loading.gif" alt="" />
			</div>
			<p style="display: flex;justify-content: center;font-size: .12rem;padding: .1rem 0;">企业数据同步加载中，请稍后...</p>
		</van-dialog>
	</div>
</template>

<script>
    import {defineComponent, toRefs, reactive,computed,watch} from "vue"
	import {useStore} from "vuex"
	import {Toast} from "vant"
	import api from "@/api/methods"
	import * as dd from 'dingtalk-jsapi';
    export default defineComponent({
        name: "company",
		emits:['to-user'],
		props:{
			modelValue:{
				type:Boolean,
				default:false
			}
		},
        setup(props, {emit}) {
        	const store = useStore();
            const state = reactive({
				list:[],
				page:1,
				page_size:15,
				total:0,
				next_time:Date.now(),
				filter:{
					value:'',
					dept_id:'',
				},
				loading:false,
				isPc:dd.pc
            });
			const current_user = computed(()=>{
				return store.state.user.current_user
			});
			watch(()=>props.modelValue,(val)=>{
				if(val){
					init();
				}
			},{immediate:true,deep:true});
            function getList(page=1) {
				let p ={
					page:page,
					page_size:999
				};
				if(state.filter.value){
					p.corp_name = state.filter.value;
				}
				state.page=page;
				return api.company.getCompanyList(p).then((res)=>{
					if(res.code==0){
						if(Array.isArray(res.data.list)){
							res.data.list.forEach((item)=>{
								item.admin = item.admin_user?item.admin_user[0].name:'--';
							});
							if(!state.filter.dept_id){
								if(current_user.value.dept_id){
									state.filter.dept_id = current_user.value.dept_id;
								}else{
									state.filter.dept_id = res.data.list[0]?.dept_id;
								}
							}
							state.total = res.data.total;
							state.list = res.data.list;
						}
					}
					return true;
				})
            }
			function scroll(e) {
				if (e.target.clientHeight + e.target.scrollTop + 1 > e.target.scrollHeight) {
					if (Date.now() - state.next_time>500&&Math.ceil(state.total/state.page_size)>state.page) {
						state.next_time = Date.now();
						state.page++;
						getList(state.page);
					}
				}
			}
			function clear(){
            	state.filter.value = '';
				getList();
			}
			function syncAllDepartmentForCompany() {
				let p = {

				};
				state.loading = true;
				api.company.syncAllDepartmentForCompany(p).then((res)=>{
					if(res.code==0){
						Toast.success({
							message: "同步成功!",
						});
						getList();
					}
					state.loading = false;
				})
			}
			function toUser(item) {
				emit('to-user',item);
			}
			function init(){
				getList();
			}
            return {
                ...toRefs(state),
				current_user,
				getList,
				clear,
				scroll,
				syncAllDepartmentForCompany,
				toUser
            }
        },
		data(){
        	return {
				isComposing:false
			}
		},
		methods:{
			handleCompositionStart() {
				this.isComposing = true;
			},
			handleCompositionEnd(event) {
				this.isComposing = false;
				this.input(event);
			},
			input(e){
				if(this.isComposing) return;
				this.getList();
			}
		}
    })
</script>

<style scoped lang="less">
	.list{
		flex: 1;
		display: flex;
		flex-direction: column;
		border-top: .03rem solid #eef2f5;
		overflow: hidden;
		.filter{
			position: relative;
			width: calc(100% - .4rem);
			display: flex;
			align-items: center;
			flex-shrink: 0;
			padding: .1rem .2rem;
			background-color: #fff;
			.filter-company{
				max-width: 2rem;
				white-space: nowrap;
				text-overflow: ellipsis;
				overflow: hidden;
				margin-right: .1rem;
				cursor: pointer;
			}
			.search-box{
				flex: 1;
				position: relative;
				display: inline-flex;
				align-items: center;
				overflow: hidden;
				.search-icon{
					position: absolute;
					left: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
				.input{
					flex: 1;
					height: .34rem;
					border-radius: 2em;
					background-color: #eef2f5;
					padding:0 .25rem 0 .3rem;
					overflow: hidden;
				}
				.clear-icon{
					position: absolute;
					right: .08rem;
					font-size: .16rem;
					color: #c4ccd9;
				}
			}
			.btn{
				display: inline-flex;
				align-items: center;
				height: .36rem;
				background-color: #2ca0f7;
				color: #fff;
				border-radius: 2em;
				padding:0 .1rem;
				margin-left: .1rem;
				cursor: pointer;
				.icon{
					font-size: .12rem;
					margin-right: .05rem;
				}
				&:active{
					opacity: .8;
				}
			}
		}
		.list-box{
			flex: 1;
			background-color: #fff;
			padding: 0 .2rem;
			overflow-y: auto;
			.list-corp{
				padding: .1rem 0;
				border-bottom: 1px solid #efefef;
				cursor: pointer;
				.corp-name{
					height: .24rem;
					display: flex;
					align-items: center;
					white-space: nowrap;
					text-overflow: ellipsis;
					overflow: hidden;
					font-size: .16rem;
					font-weight: bolder;
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-user{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				.avatar{
					width: .5rem;
					height: .5rem;
					display: inline-flex;
					justify-content: center;
					align-items: center;
					color: #fff;
					background-color: #2aa0f7;
					border-radius: 50%;
					border: 2px solid #fff;
					font-size: .12rem;
				}
				.role{
					flex: 1;
					display: flex;
					flex-direction: column;
					margin:0 .1rem;
					overflow: hidden;
					.role-name{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.name{
							min-width: 0;
							color: #333;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
							font-size: .16rem;
							font-weight: bolder;
						}
						.app-admin{
							flex-shrink: 0;
							border-radius: 1em;
							border: 1px solid #fff440;
							color: #fff440;
							padding:.02rem .08rem;
							margin-left: .05rem;
							font-size: .12rem;
						}
					}
					.role-mobile{
						flex: 1;
						display: flex;
						align-items: center;
						overflow: hidden;
						.mobile{
							flex: 1;
							color: #999;
							white-space: nowrap;
							text-overflow: ellipsis;
							overflow: hidden;
						}
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.list-device{
				padding: .1rem 0;
				display: flex;
				border-bottom: 1px solid #efefef;
				.device{
					flex: 1;
					overflow: hidden;
					.device-name{
						min-width: 0;
						color: #333;
						white-space: nowrap;
						text-overflow: ellipsis;
						overflow: hidden;
						font-size: .16rem;
						font-weight: bolder;
					}
					.device-id{
						color: #999;
						display: flex;
						align-items: center;
						height: .3rem;
						overflow: hidden;
					}
				}
				.auth{
					display: inline-flex;
					align-items: center;
					.switch{
						color: #2aa0f7;
						margin: 0 .1rem;
						cursor: pointer;
					}
					.delete{
						color: #F56C6C;
						margin:0 .1rem;
						cursor: pointer;
					}
				}
				&:last-of-type{
					border-bottom: none;
				}
			}
			.col{
				display: flex;
				justify-content: space-between;
				align-items: center;
				height: .24rem;
				color: #999;
			}
		}
	}
</style>
